export const yenFormatInstance = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY"
}).format;

export function yenFormat(num, fallback) {
  return !num || isNaN(num) ? fallback || "" : yenFormatInstance(num);
}

export const numFormatInstance = new Intl.NumberFormat("ja-JP").format;

export function numFormat(num, fallback) {
  return !num || isNaN(num) ? fallback || "" : numFormatInstance(num);
}

export const dateFormatInstance = Intl.DateTimeFormat("ja", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit"
}).format;

export function dateFormat(date, fallback) {
  return !date || date === "" ? fallback || "" : dateFormatInstance(date);
}
